@import url("https://p.typekit.net/p.css?s=1&k=xcj8vkn&ht=tk&f=49648.49651.51647.51652.49654.51655&a=129677228&app=typekit&e=css");

@font-face {
font-family:"articulat-cf";
src:url("https://use.typekit.net/af/912a6a/00000000000000007735fae9/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/912a6a/00000000000000007735fae9/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/912a6a/00000000000000007735fae9/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"articulat-cf";
src:url("https://use.typekit.net/af/f3b3e0/00000000000000007735faec/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/f3b3e0/00000000000000007735faec/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/f3b3e0/00000000000000007735faec/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"articulat-cf";
src:url("https://use.typekit.net/af/034236/0000000000000000774b9f42/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/034236/0000000000000000774b9f42/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/034236/0000000000000000774b9f42/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"articulat-cf";
src:url("https://use.typekit.net/af/82cb04/0000000000000000774b9f48/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/82cb04/0000000000000000774b9f48/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/82cb04/0000000000000000774b9f48/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"articulat-heavy-cf";
src:url("https://use.typekit.net/af/49e098/00000000000000007735faef/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/49e098/00000000000000007735faef/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/49e098/00000000000000007735faef/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
}

@font-face {
font-family:"articulat-heavy-cf";
src:url("https://use.typekit.net/af/028cec/0000000000000000774b9f45/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/028cec/0000000000000000774b9f45/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/028cec/0000000000000000774b9f45/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:900;font-stretch:normal;
}

.tk-articulat-cf { font-family: "articulat-cf",sans-serif; }
.tk-articulat-heavy-cf { font-family: "articulat-heavy-cf",sans-serif; }
html {
  background-color: #000000;
  /* font-family: "articulat-cf"; */
  font-family: system-ui, Segoe UI; 
  font-display: swap;
  max-width: 100%;
  min-height: auto;
  overflow-x: hidden;
  color:#ffffff;

}

.Footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	color: white;
	text-align: center;
}

.FooterLink {
	text-decoration: none;
	background-color: none;
	color: #ffffff;
}

.Navbar {
	display: flex;
	width: 200px;
	height: 320px;
	margin-left: 40px;
	padding: 10px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;
	z-index: 100;
	flex-shrink: 0;
}
.NavbarText {
	padding: 5px;
	text-decoration: none;
	background-color: none;
	color: #ffffff;
	font-size: 40px;
	transition: color ease-in-out 100ms;
}
.NavbarText:hover {
	color: #00009f;
}

.ContactDiv {
	color: #ffffff;
	display: flex;
	min-height: 100vh;

	z-index: 0;
	flex-direction: column;
	align-items: center;
}
