@import "./fonts.css";

html {
  background-color: #000000;
  /* font-family: "articulat-cf"; */
  font-family: system-ui, Segoe UI; 
  font-display: swap;
  max-width: 100%;
  min-height: auto;
  overflow-x: hidden;
  color:#ffffff;

}

.Footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	color: white;
	text-align: center;
}

.FooterLink {
	text-decoration: none;
	background-color: none;
	color: #ffffff;
}

.Navbar {
	display: flex;
	width: 200px;
	height: 320px;
	margin-left: 40px;
	padding: 10px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;
	z-index: 100;
	flex-shrink: 0;
}
.NavbarText {
	padding: 5px;
	text-decoration: none;
	background-color: none;
	color: #ffffff;
	font-size: 40px;
	transition: color ease-in-out 100ms;
}
.NavbarText:hover {
	color: #00009f;
}

.ContactDiv {
	color: #ffffff;
	display: flex;
	min-height: 100vh;

	z-index: 0;
	flex-direction: column;
	align-items: center;
}